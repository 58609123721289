import React from "react";
import { MentionedInConversation } from "../../models/notifications";
import useBrowserLanguage from "../../hooks/useBrowserLanguage";

const MentionedInConversationNotification: React.FC<{
  notification: MentionedInConversation;
}> = ({ notification }) => {
  const { getTextInBrowserLanguage } = useBrowserLanguage();
  return (
    <div>
      <span className=" font-semibold">{notification.mentioned_by.name}</span>{" "}
      {getTextInBrowserLanguage({
        en: "mentioned you in a conversation",
        fr: "vous a mentionné dans une conversation",
      })}
    </div>
  );
};

export default MentionedInConversationNotification;
