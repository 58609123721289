export enum CampaignRoleEnum {
  owner = "owner",
  viewer = "viewer",
  editor = "editor",
}

export type CampaignPolicy = {
  can_import_conversations?: boolean;
  can_delete_conversations?: boolean;
  can_update_conversations_metadata?: boolean;
  can_view_evaluation_grid?: boolean;
  can_update_evaluation_grid?: boolean;
  can_update_custom_analyses?: boolean;
  can_update_evaluations?: boolean;
  can_validate_evaluations?: boolean;
  can_unvalidate_evaluations?: boolean;
  can_update_campaign?: boolean;
  can_add_users?: boolean;
  can_update_users_access?: boolean;
  can_delete_users?: boolean;
};

export type CampaignUser = {
  id: string;
  email: string;
  role: CampaignRoleEnum;
  policy?: CampaignPolicy;
};
