export enum OperatorEnum {
  gt = "gt",
  gte = "gte",
  lt = "lt",
  lte = "lte",
  eq = "eq",
}

export enum RuleTypeEnum {
  main_score = "main_score",
  additional_score = "additional_score",
  section_score = "section_score",
  multiple_levels_item = "multiple_levels_item",
  binary_item = "binary_item",
  composed_item = "composed_item",
}

export type MainScoreAlertRule = {
  rule_type: RuleTypeEnum.main_score;
  operator: OperatorEnum;
  threshold: number;
};

export type AdditionalScoreAlertRule = {
  rule_type: RuleTypeEnum.additional_score;
  operator: OperatorEnum;
  threshold: number;
  score_id: string;
};

export type SectionScoreAlertRule = {
  rule_type: RuleTypeEnum.section_score;
  operator: OperatorEnum;
  threshold: number;
  section_id: string;
};

export type MultipleLevelItemAlertRule = {
  rule_type: RuleTypeEnum.multiple_levels_item;
  item_id: string;
  levels: string[];
};

export type BinaryItemAlertRule = {
  rule_type: RuleTypeEnum.binary_item;
  item_id: string;
};

export type ComposedItemAlertRule = {
  rule_type: RuleTypeEnum.composed_item;
  item_id: string;
  levels: string[];
};

export type AlertRule =
  | MainScoreAlertRule
  | AdditionalScoreAlertRule
  | SectionScoreAlertRule
  | MultipleLevelItemAlertRule
  | BinaryItemAlertRule
  | ComposedItemAlertRule;

export type Alert = {
  id: string;
  campaign_id: string;
  user_id: string;
  alert_type: string[];
  rule: AlertRule;
  created_at: number;
};
