import { OperatorEnum, RuleTypeEnum } from "./alerts";
import { UserBase } from "./users";

type NotificationBase = {
  id: string;
  user_id: string;
  read: boolean;
  created_at: number;
};

export enum NotificationTypeEnum {
  MENTIONED_IN_CONVERSATION = "MENTIONED_IN_CONVERSATION",
  ALERT = "ALERT",
}

export type MentionedInConversation = NotificationBase & {
  type: NotificationTypeEnum.MENTIONED_IN_CONVERSATION;
  conversation_id: string;
  mentioned_by: UserBase;
};

export type MainScoreAlertDetail = {
  type: RuleTypeEnum.main_score;
  score: Number;
  operator: OperatorEnum;
};

export type SectionScoreAlertDetail = {
  type: RuleTypeEnum.section_score;
  section_id: string;
  score_name: string;
  score: Number;
  operator: OperatorEnum;
};

export type AdditionalScoreAlertDetail = {
  type: RuleTypeEnum.additional_score;
  score_id: string;
  score_name: string;
  score: Number;
  operator: OperatorEnum;
};

export type BinaryItemAlertDetail = {
  type: RuleTypeEnum.binary_item;
  item_id: string;
  item_name: string;
};

export type MultipleLevelsItemAlertDetail = {
  type: RuleTypeEnum.multiple_levels_item;
  item_id: string;
  item_name: string;
};

export type ComposedItemAlertDetail = {
  type: RuleTypeEnum.composed_item;
  item_id: string;
  item_name: string;
};

export type AlertDetail =
  | MainScoreAlertDetail
  | SectionScoreAlertDetail
  | AdditionalScoreAlertDetail
  | BinaryItemAlertDetail
  | MultipleLevelsItemAlertDetail
  | ComposedItemAlertDetail;

export type AlertNotification = NotificationBase & {
  type: NotificationTypeEnum.ALERT;
  campaign_name: string;
  conversation_id: string;
  alert_detail: AlertDetail;
};

export type Notification = MentionedInConversation | AlertNotification;
